import AddToCart from 'Component/AddToCart';
import BlackFridayCounter from 'Component/BlackFridayCounter';
import CmsBlock from 'Component/CmsBlock';
import CmsPopup from 'Component/CmsPopup';
import ColorSelect from 'Component/ColorSelect';
import { waitForElm } from 'Component/Config';
import DimensionFormatter from 'Component/DimensionFormatter';
import FieldHidden from 'Component/FieldHidden';
import FlashSaleIndicator from 'Component/FlashSalesIndicator';
import IfStore from 'Component/IfStore';
import Link from 'Component/Link';
import McAvailability from 'Component/McAvailability';
import McDimensioniPopup from 'Component/McDimensioniPopup';
import Popup from 'Component/Popup';
import {
    CATAS_CUSTOM_POPUP,
    CATAS_MATERASSI_POPUP,
    COSMOB_CUSTOM_POPUP,
    COSMOB_MATERASSI_POPUP,
    GARANZIA_CUSTOM_POPUP,
    METODO_CONSEGNA_CUSTOM_POPUP,
    METODO_CONSEGNA_CUSTOM_POPUP_NEW,
    SERVIZI_CUSTOM_POPUP,
    VARIANT_POPUP,
} from 'Component/ProductActions/ProductActions.config';
import ProductComposizioneTipo from 'Component/ProductCardDetails/Elements/ProductComposizioneTipo.component'; // FORSE DA SPOSTARE COME COMPONENTE PRINCIPALE
import { MATERASSI_VARIANTI } from 'Component/ProductCardDetails/ProductCardDetails.config';
import ProductPrice from 'Component/ProductPrice';
import SelectConfiguration from 'Component/SelectConfiguration';
import TextPlaceholder from 'Component/TextPlaceholder';
import { CATEGORY_ID_ANGOLO_DELLE_OCCASIONI } from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import { ProductActionsComponent as SourceProductActions } from 'SourceComponent/ProductActions/ProductActions.component';
import { DeviceType } from 'Type/Device.type';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import isOnlySct from 'Util/isOnlySct';

import {
    BOOK_POPUP_BEDROOM,
    BOOK_POPUP_KITCHEN,
} from './ProductActions.config';

import './ProductActions.override.style';

/** @namespace Pwa/Component/ProductActions/Component */
export class ProductActionsComponent extends SourceProductActions {
    static propTypes = {
        ...SourceProductActions.propTypes,
        device: DeviceType,
    };

    __construct() {
        this.state = {
            ...this.state,
            isDropdownShown: false,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        const {
            product: {
                sku,
                name,
                categories,
                price_range: {
                    maximum_price: {
                        discount: { amount_off } = {},
                        final_price: { currency, value } = {},
                    } = {},
                } = {},
                mc_techspecs_template,
            },
        } = this.props;

        if (
            categories.find(
                (elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI
            )
        ) {
            this.setState({ isADO: true });
        }

        // EVENTI G4
        window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
        window?.dataLayer?.push({
            event: 'view_item',
            ecommerce: {
                items: [
                    {
                        item_id: sku,
                        item_name: name,
                        affiliation: 'store online',
                        currency,
                        discount: amount_off,
                        index: 0,
                        item_brand: 'Mondo Convenienza',
                        item_category: mc_techspecs_template,
                        item_list_name: `Categoria ${mc_techspecs_template}`,
                        price: value,
                        quantity: 1,
                    },
                ],
            },
        });
        this.moveChat('91px');
    }

    async moveChat(bottom) {
        if (window.innerWidth < 810) {
            const chat = await waitForElm(
                '.embeddedMessagingConversationButton'
            );

            if (chat) {
                chat.style.bottom = bottom;
            }
        }
    }

    componentWillUnmount() {
        this.moveChat('');
    }

    templatePage = () => {
        const {
            product: { nuovo_template_option },
        } = this.props;

        switch (nuovo_template_option.label) {
        case 'template_camere':
            return this.renderTemplateCamere();
        case 'template_cucine_componibili':
            return this.renderTemplateCucine();
        case 'template_cucine_componibiliV2':
            return this.renderTemplateDivaniOrCucineV2();
        case 'template_cucine_bloccate':
            return this.renderTemplateCucineBloccate();
        case 'template_divani':
            return this.renderTemplateDivaniOrCucineV2();
        case 'template_letti':
            return this.renderTemplateLetti();
        case 'template_camerette':
            return this.renderTemplateCamerette();
        case 'template_bagni':
            return this.renderTemplateBagni();
        case 'template_soggiorni_madie':
            return this.renderTemplateSoggiorniMadie();
        case 'template_sedie_altro':
            return this.renderTemplateSedieAltro();
        case 'template_camerette_bloccate':
            return this.renderTemplateCameretteBloccate();
        case 'template_tavoli':
            return this.renderTemplateTavoli();
        case 'template_materassi':
            return this.renderTemplateMaterassi();
        case 'template_reti':
            return this.renderTemplateReti();
        case 'template_elettrodomestici':
            return this.renderTemplateElettrodomestici();
        case 'template_armadi':
        case 'template_armadi2':
            return this.renderTemplateArmadi();
        default:
            return this.renderOldTemplate();
        }
    };

    renderFieldHidden() {
        const { quantity } = this.props;

        return (
            <FieldHidden
              attr={ {
                  id: 'item_qty',
                  name: 'item_qty',
              } }
              value={ quantity }
            />
        );
    }

    renderAddToCartPopup(product) {
        return (
            <AddToCart
              product={ product }
              mix={ { block: 'ProductActions', elem: 'AddToCartPopup' } }
              quantity={ 1 }
              fromPDP
              list="third level product page"
            />
        );
    }

    renderAddToCart() {
        const {
            product,
            product: { categories, mc_black_friday_counter, attribute_set },
            thirdLevelQTY,
            quantity,
            showPopup,
        } = this.props;

        const BOOK_POPUP = attribute_set.includes('Cucine')
            ? BOOK_POPUP_KITCHEN
            : BOOK_POPUP_BEDROOM;

        if (
            mc_black_friday_counter?.items_remaining === 0
            || mc_black_friday_counter?.items_remaining === -1
        ) {
            return (
                <button className="Button AddToCart-Disabled">
                    <span>{ __('Add to cart') }</span>
                </button>
            );
        }

        if (
            (thirdLevelQTY === 0 || thirdLevelQTY === -1)
            && categories.find(
                (elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI
            )
        ) {
            return (
                <button className="Button AddToCart-Disabled">
                    <span>{ __('Add to cart') }</span>
                </button>
            );
        }

        // eslint-disable-next-line default-case
        switch (product.mc_vendibile_sito) {
        case 1:
            return (
                    <AddToCart
                      product={ product }
                      mix={ { block: 'ProductActions', elem: 'AddToCart' } }
                      quantity={ quantity }
                      list="third level product page"
                      fromPDP
                        //   configurableVariantIndex={ configurableVariantIndex }
                        //   groupedProductQuantity={ groupedProductQuantity }
                        //   onProductValidationError={ onProductValidationError }
                        //   productOptionsData={ productOptionsData }
                    />
            );
        case 0:
            const link = ['/progettare-su-misura-consulenza'];
            switch (product.mc_inline_description) {
            case 'Cucina componibile':
                link[0] = '/progettare-cucina-su-misura-consulenza';
                break;
            case 'Cameretta componibile':
                link[0] = '/progettare-cameretta-su-misura-consulenza';
                break;
            default:
                link[0] = __('/progettare-su-misura-consulenza');
            }

            return (
                    <button
                      block="Button Video"
                      onClick={ () => {
                          showPopup(BOOK_POPUP);
                      } }
                    >
                        { __('Prenota un appuntamento') }
                    </button>
            );
        }

        return (
            <AddToCart
              product={ product }
              mix={ { block: 'ProductActions', elem: 'AddToCart' } }
              quantity={ quantity }
              list="third level product page"
              fromPDP
                //   configurableVariantIndex={ configurableVariantIndex }
                //   groupedProductQuantity={ groupedProductQuantity }
                //   onProductValidationError={ onProductValidationError }
                //   productOptionsData={ productOptionsData }
            />
        );
    }

    renderMcDescrizione() {
        const {
            product: { mc_inline_description },
        } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ {
                  isLoaded: !!mc_inline_description,
                  type: 'mcInlineDescription',
              } }
            >
                <h2 className="mc_inline_description">
                    <TextPlaceholder
                      content={ mc_inline_description }
                      length="short"
                    />
                </h2>
            </section>
        );
    }

    renderMcColorInfo() {
        const {
            product: { mc_color_information },
        } = this.props;
        const { description } = mc_color_information || {};

        return (
            mc_color_information && (
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: 'mcColorInfo' } }
                >
                    <h2 className="mc_color_info">
                        <TextPlaceholder content={ description } length="short" />
                    </h2>
                </section>
            )
        );
    }

    renderSconto(image) {
        return (
            image && (
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: 'mcSconto' } }
                >
                    <img
                      block="ProductActions"
                      elem="Sconto"
                      src={ `${image}` }
                      alt=""
                    />
                </section>
            )
        );
    }

    renderMcDescrizione2() {
        const {
            product: { mc_inline_description2 },
        } = this.props;

        return (
            mc_inline_description2 && (
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: 'mcInlineDescription2' } }
                >
                    <span>{ mc_inline_description2 }</span>
                </section>
            )
        );
    }

    renderMcDimensioniPopover() {
        const {
            product: { sku, mc_products_sizes },
        } = this.props;

        const id_popup = `DIMENSIONI_PRODOTTO_${sku}`;

        return (
            <McDimensioniPopup
              mcProductSizes={ mc_products_sizes }
              id={ id_popup }
            />
        );
    }

    renderMcComposizioneTipo() {
        const { product } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'mcComposizioneTipo' } }
            >
                <ProductComposizioneTipo product={ product } />
            </section>
        );
    }

    renderMcDimensioni() {
        const {
            product: {
                mc_dimension_height,
                mc_dimension_width,
                mc_dimension_depth,
                sku,
                mc_products_sizes,
            },
        } = this.props;

        if (mc_products_sizes) {
            const id_popup = `DIMENSIONI_PRODOTTO_${sku}`;

            return (
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: 'mcDimensioni' } }
                >
                    <McDimensioniPopup
                      mcProductSizes={ mc_products_sizes }
                      id={ id_popup }
                    />
                </section>
            );
        }
        const hasDimension = !!(
            mc_dimension_height
            && mc_dimension_width
            && mc_dimension_depth
        );

        return (
            hasDimension && (
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: 'mcDimensioni' } }
                >
                    <DimensionFormatter
                      height={ mc_dimension_height }
                      width={ mc_dimension_width }
                      depth={ mc_dimension_depth }
                    />
                </section>
            )
        );
    }

    renderSchedaTecnicaLink() {
        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'SchedaTecnicaLink' } }
            >
                <p
                  onClick={ () => this.scrollToTecnica() }
                  className="schedaTecnicaAnchor"
                >
                    { __('Scheda tecnica') }
                </p>
            </section>
        );
    }

    scrollToTecnica() {
        const element = document.getElementById('instructions'); // Ottiene l'elemento con ID 'instruction'

        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.pageYOffset; // Calcola la posizione dell'elemento
            // Effettua lo scroll con comportamento "smooth"
            window.scrollTo({
                top: elementPosition,
                behavior: 'smooth', // Scorrimento fluido
            });
        }
    }

    renderCodice() {
        const {
            product: { sku },
        } = this.props;

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'CodiceSku' } }
            >
                <span>
                    { __('codice:') }
                    &nbsp;
                    { sku }
                </span>
            </section>
        );
    }

    renderColoriDisponibili() {
        const {
            product: {
                mc_available_colors,
                mc_color_logo,
                mc_etichetta_colore,
            },
        } = this.props;

        const isVariant = mc_etichetta_colore === MATERASSI_VARIANTI;
        const availableLabel = isVariant
            ? __('%s variants available', mc_available_colors?.length)
            : __('%s colors available', mc_available_colors?.length);

        return (
            !mc_color_logo
            && mc_available_colors?.length > 0 && (
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: 'ColoriDisponibili' } }
                >
                    <h2 className="colori_disponibili">
                        { mc_available_colors?.length > 1 && availableLabel }
                    </h2>
                </section>
            )
        );
    }

    renderFlexMediaButton() {
        const {
            product: { mc_code_ean, brand_flixmedia },
        } = this.props;

        if (mc_code_ean && brand_flixmedia) {
            return (
                <section
                  id="flix-button"
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: 'FlixMedia' } }
                />
            );
        }

        return null;
    }

    renderDisponibilita() {
        const {
            product: { sku, mc_vendibile_sito },
            product,
        } = this.props;

        if (!mc_vendibile_sito) {
            return null;
        }

        return <McAvailability sku={ sku } isOnlySct={ isOnlySct(product) } />;
    }

    renderColorSelect() {
        const {
            product,
            product: { mc_available_colors = [], mc_color } = {},
        } = this.props;
        const selectedOption = mc_available_colors.find(
            (elem) => elem.mc_color === mc_color
        );

        const value = selectedOption
            ? {
                value: selectedOption.sku,
                name: selectedOption.name,
                label: selectedOption.mc_color_information.description,
                image: selectedOption.mc_color_information.image_url,
                url: selectedOption.url,
                price_range: selectedOption.price_range,
                mc_dimension_depth: selectedOption.mc_dimension_depth,
                mc_dimension_height: selectedOption.mc_dimension_height,
                mc_dimension_width: selectedOption.mc_dimension_width,
            }
            : null;

        const options = mc_available_colors
        // FILTER TEMPLATE DIVANI 2
            .filter((product) => product.nuovo_template_option.label !== 'template_divani_2')
            .map((item) => ({
                value: item.sku,
                label: item.mc_color_information.description,
                image: item.thumbnail.url,
                url: item.url,
                name: item.name,
                price_range: item.price_range,
                nuovo_template_option: item.nuovo_template_option,
                mc_dimension_depth: item.mc_dimension_depth,
                mc_dimension_height: item.mc_dimension_height,
                mc_dimension_width: item.mc_dimension_width,
            }));

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'ColorSelect' } }
            >
                <ColorSelect
                  product={ product }
                  options={ options }
                  value={ value }
                />
            </section>
        );
    }

    renderSelectConfiguration() {
        const { product } = this.props;

        return <SelectConfiguration product={ product } />;
    }

    renderConfiguration() {
        const {
            product,
            product: { mc_dishwasher_variant_information },
        } = this.props;

        if (
            !mc_dishwasher_variant_information
            || !mc_dishwasher_variant_information.length
        ) {
            return null;
        }

        return (
            Boolean(mc_dishwasher_variant_information[0].link) && (
                <div className="select-lavello">
                    <Link
                      to={ product?.url }
                      className={ `${
                          product.url !== window.location.pathname
                              ? 'disabled'
                              : ''
                      }  ${
                          product.has_dishwasher == '1' ? 'first' : 'second'
                      }` }
                    >
                        { product.has_dishwasher == '1'
                            ? __('predisposta per lavastoviglie')
                            : __('senza lavastoviglie') }
                    </Link>
                    <Link
                      to={ mc_dishwasher_variant_information[0]?.link }
                      className={ `${
                          mc_dishwasher_variant_information[0]?.link
                            !== window.location.pathname
                              ? 'disabled'
                              : ''
                      } ${
                          mc_dishwasher_variant_information[0]
                              .has_dishwasher == '1'
                              ? 'first'
                              : 'second'
                      }` }
                    >
                        { mc_dishwasher_variant_information[0].has_dishwasher
                        == '1'
                            ? __('predisposta per lavastoviglie')
                            : __('senza lavastoviglie') }
                    </Link>
                </div>
            )
        );
    }

    renderButtonAbbinati() {
        const {
            product: {
                mc_sid_abbinati,
                nuovo_template_option,
                mc_techspecs_template,
            },
        } = this.props;

        const sorting = {
            first: [
                'con contenitore',
                'a terra',
                'Letto matrimoniale',
                '2 posti',
                'sinistro',
                'Comodino',
            ],
            second: [
                'giroletto',
                'sospesi',
                'Letto 1 piazza e mezza',
                '3 posti',
                'destro',
                'Settiminio',
            ],
            third: ['Letto non presente', 'Letto singolo', 'poltrona', 'Comò'],
        };

        if (mc_sid_abbinati.length < 1 || mc_sid_abbinati === undefined || mc_sid_abbinati === null) {
            return null;
        }

        let sid_abbonati = [];
        let isTavoli = false;

        if (
            nuovo_template_option.label === 'template_tavoli'
            || nuovo_template_option.label === 'template_sedie_altro'
        ) {
            isTavoli = true;
            sid_abbonati = mc_sid_abbinati
                .sort((a, b) => parseInt(a.label) - parseInt(b.label))
                .map((elem) => ({
                    ...elem,
                    label:
                        mc_techspecs_template == 'como_e_comodini'
                            ? elem.label
                            : `${elem.label} cm`,
                }));
        } else {
            sid_abbonati.push(
                mc_sid_abbinati.find((a) => sorting.first.indexOf(a.label) > -1)
            );
            sid_abbonati.push(
                mc_sid_abbinati.find(
                    (a) => sorting.second.indexOf(a.label) > -1
                )
            );
            sid_abbonati.push(
                mc_sid_abbinati.find((a) => sorting.third.indexOf(a.label) > -1)
            );
        }

        return (
            <>
                { sid_abbonati.filter((a) => a !== undefined && a.url !== null)
                    .length >= 2 && (
                    <h2 className="buttons-abbinati">
                        { this.renderAbbinatiTitle(sid_abbonati) }
                    </h2>
                ) }
                { sid_abbonati.filter((a) => a !== undefined && a.url !== null)
                    .length >= 2 && (
                    <div
                      className={ `${
                          isTavoli ? 'tavoli' : ''
                      } buttons-abbinati` }
                    >
                        { sid_abbonati
                            .filter((a) => a !== undefined && a.url !== null)
                            .map((item) => (
                                <a
                                  href={ item.url }
                                  className={ `${
                                      item.url !== window.location.pathname
                                          ? 'disabled'
                                          : ''
                                  }` }
                                  style={ {
                                      textTransform:
                                            nuovo_template_option.label
                                                === 'template_tavoli'
                                            || nuovo_template_option.label
                                                === 'template_sedie_altro'
                                                ? mc_techspecs_template
                                                  == 'como_e_comodini'
                                                    ? 'uppercase'
                                                    : 'lowercase'
                                                : '',
                                  } }
                                  onClick={ () =>
                                      window.dataLayer.push({
                                          event: 'select_item',
                                          action_type: 'type',
                                          ecommerce: {
                                              items: [
                                                  {
                                                      item_url: item.url,
                                                      affiliation:
                                                            'store online',
                                                      currency: 'EUR',
                                                      item_brand:
                                                            'Mondo Convenienza',
                                                      quantity: 1,
                                                  },
                                              ],
                                          },
                                      }) }
                                >
                                    { this.renderLabelAbbinati(item.label) }
                                </a>
                            )) }
                    </div>
                ) }
            </>
        );
    }

    renderMisureAbbinati() {
        const {
            product: {
                mc_misure_sid_abbinati,
                nuovo_template_option,
                mc_techspecs_template,
            },
        } = this.props;

        let sid_abbonati = [];
        const isTavoli = true;

        if (mc_misure_sid_abbinati) {
            sid_abbonati = mc_misure_sid_abbinati
                .sort((a, b) => parseInt(a.label) - parseInt(b.label))
                .map((elem) => ({
                    ...elem,
                    label:
                        mc_techspecs_template == 'como_e_comodini'
                            ? elem.label
                            : `${elem.label} cm`,
                }));
        }

        return (
            <>
                { sid_abbonati.filter((a) => a !== undefined && a.url !== null)
                    .length >= 2 && (
                    <h2 className="buttons-abbinati">SCEGLI LA DIMENSIONE</h2>
                ) }
                { sid_abbonati.filter((a) => a !== undefined && a.url !== null)
                    .length >= 2 && (
                    <div
                      className={ `${
                          isTavoli ? 'tavoli' : ''
                      } buttons-abbinati` }
                    >
                        { sid_abbonati
                            .filter((a) => a !== undefined && a.url !== null)
                            .map((item) => (
                                <a
                                  href={ item.url }
                                  className={ `${
                                      item.url !== window.location.pathname
                                          ? 'disabled'
                                          : ''
                                  }` }
                                  style={ {
                                      textTransform:
                                            nuovo_template_option.label
                                                === 'template_tavoli'
                                            || nuovo_template_option.label
                                                === 'template_sedie_altro'
                                                ? mc_techspecs_template
                                                  == 'como_e_comodini'
                                                    ? 'uppercase'
                                                    : 'lowercase'
                                                : '',
                                  } }
                                  onClick={ () =>
                                      window.dataLayer.push({
                                          event: 'select_item',
                                          action_type: 'type',
                                          ecommerce: {
                                              items: [
                                                  {
                                                      item_url: item.url,
                                                      affiliation:
                                                            'store online',
                                                      currency: 'EUR',
                                                      item_brand:
                                                            'Mondo Convenienza',
                                                      quantity: 1,
                                                  },
                                              ],
                                          },
                                      }) }
                                >
                                    { this.renderLabelAbbinati(item.label) }
                                </a>
                            )) }
                    </div>
                ) }
            </>
        );
    }

    renderDescrizioniAbbinati() {
        const {
            product: { mc_descrizione_caratteristiche_specifiche_sid_abbinati },
        } = this.props;

        return (
            <>
                { mc_descrizione_caratteristiche_specifiche_sid_abbinati.filter(
                    (a) => a !== undefined && a.url !== null
                ).length >= 2 && (
                    <h2 className="buttons-abbinati">
                        { __('SCEGLI LA TIPOLOGIA DI CONTENITORE') }
                    </h2>
                ) }
                { mc_descrizione_caratteristiche_specifiche_sid_abbinati.filter(
                    (a) => a !== undefined && a.url !== null
                ).length >= 2 && (
                    <div className="buttons-abbinati">
                        { mc_descrizione_caratteristiche_specifiche_sid_abbinati
                            .filter((a) => a !== undefined && a.url !== null)
                            .map((item) => (
                                <a
                                  href={ item.url }
                                  className={ `${
                                      item.url !== window.location.pathname
                                          ? 'disabled'
                                          : ''
                                  }` }
                                  style={ {
                                      textTransform: 'uppercase',
                                  } }
                                  onClick={ () =>
                                      window.dataLayer.push({
                                          event: 'select_item',
                                          action_type: 'type',
                                          ecommerce: {
                                              items: [
                                                  {
                                                      item_url: item.url,
                                                      affiliation:
                                                            'store online',
                                                      currency: 'EUR',
                                                      item_brand:
                                                            'Mondo Convenienza',
                                                      quantity: 1,
                                                  },
                                              ],
                                          },
                                      }) }
                                >
                                    { this.renderLabelAbbinati(item.label) }
                                </a>
                            )) }
                    </div>
                ) }
            </>
        );
    }

    renderButtonAbbinatiDivani() {
        const {
            product: { mc_sid_abbinati },
        } = this.props;

        const sorting = {
            first: ['poltrona'],
            second: ['2 posti'],
            third: ['3 posti'],
            fourth: ['sinistro'],
            fifth: ['destro'],
        };

        const size = ['2 posti', '3 posti', 'poltrona'];

        if (!mc_sid_abbinati || !mc_sid_abbinati.length) {
            return null;
        }

        const sid_abbonati = [];
        sid_abbonati.push(
            mc_sid_abbinati.find((a) => sorting.first.indexOf(a.label) > -1)
        );
        sid_abbonati.push(
            mc_sid_abbinati.find((a) => sorting.second.indexOf(a.label) > -1)
        );
        sid_abbonati.push(
            mc_sid_abbinati.find((a) => sorting.third.indexOf(a.label) > -1)
        );
        sid_abbonati.push(
            mc_sid_abbinati.find((a) => sorting.fourth.indexOf(a.label) > -1)
        );
        sid_abbonati.push(
            mc_sid_abbinati.find((a) => sorting.fifth.indexOf(a.label) > -1)
        );

        const cleanSidAbbonati = sid_abbonati.filter(
            (item) => item !== undefined
        );

        const sizeArray = cleanSidAbbonati.filter((item) =>
            size.includes(item.label));
        const directionArray = cleanSidAbbonati.filter(
            (item) => !size.includes(item.label)
        );

        const bothButtons = sizeArray.length > 1 && directionArray.length > 1;

        return this.renderButtons(sizeArray, directionArray, bothButtons);
    }

    renderButtons(sizeArray, directionArray, bothButtons) {
        const visibleDirection = Boolean(
            directionArray.find((item) => item.url === window.location.pathname)
        );

        return (
            <>
                { sizeArray.length >= 2 && (
                    <h2 className="buttons-abbinati">
                        { this.renderAbbinatiTitle(sizeArray) }
                    </h2>
                ) }
                { sizeArray.length >= 2 && (
                    <div className="buttons-abbinati divani">
                        { sizeArray.map((item) => (
                            <a
                              href={ item.url }
                              className={ `${
                                  item.url !== window.location.pathname
                                      ? 'disabled'
                                      : ''
                              }` }
                              onClick={ () =>
                                  window?.dataLayer?.push({
                                      event: 'select_item',
                                      action_type: 'type',
                                      ecommerce: {
                                          items: [
                                              {
                                                  item_url: item.url,
                                                  affiliation: 'store online',
                                                  currency: 'EUR',
                                                  item_brand:
                                                        'Mondo Convenienza',
                                                  quantity: 1,
                                              },
                                          ],
                                      },
                                  }) }
                            >
                                { this.renderLabelAbbinati(item.label) }
                            </a>
                        )) }
                        { bothButtons && (
                            <a
                              href={ directionArray[0].url }
                              className={ `${
                                  visibleDirection ? '' : 'disabled'
                              }` }
                              onClick={ () =>
                                  window.dataLayer.push({
                                      event: 'select_item',
                                      action_type: 'type',
                                      ecommerce: {
                                          items: [
                                              {
                                                  item_url:
                                                        directionArray[0].url,
                                                  affiliation: 'store online',
                                                  currency: 'EUR',
                                                  item_brand:
                                                        'Mondo Convenienza',
                                                  quantity: 1,
                                              },
                                          ],
                                      },
                                  }) }
                            >
                                { __('PENISOLA') }
                            </a>
                        ) }
                    </div>
                ) }
                { visibleDirection && directionArray.length >= 2 && (
                    <>
                        <h2 className="buttons-abbinati">
                            { __('SCEGLI LA POSIZIONE DELLA PENISOLA') }
                        </h2>
                        <div className="buttons-abbinati">
                            { directionArray.map((item) => (
                                <a
                                  key={ item.url }
                                  href={ item.url }
                                  className={ `${
                                      item.url !== window.location.pathname
                                          ? 'disabled'
                                          : ''
                                  }` }
                                  onClick={ () =>
                                      window?.dataLayer?.push({
                                          event: 'select_item',
                                          action_type: 'type',
                                          ecommerce: {
                                              items: [
                                                  {
                                                      item_url: item.url,
                                                      affiliation:
                                                            'store online',
                                                      currency: 'EUR',
                                                      item_brand:
                                                            'Mondo Convenienza',
                                                      quantity: 1,
                                                  },
                                              ],
                                          },
                                      }) }
                                >
                                    { this.renderLabelAbbinati(item.label) }
                                </a>
                            )) }
                        </div>
                    </>
                ) }
            </>
        );
    }

    renderAbbinatiTitle(sid_abbonati) {
        const {
            product: { attribute_set },
        } = this.props;

        switch (attribute_set) {
        case 'Pim Gruppi letto':
            return __('SCEGLI IL MODELLO');
        case 'Pim Camere Complete':
            return __('SCEGLI LA TIPOLOGIA DI LETTO DA ABBINARE');
        case 'Pim Bagno':
            return __('SCEGLI LA TIPOLOGIA');
        case 'Pim Letti':
            return __('SCEGLI LA DIMENSIONE');
        case 'Pim Camerette bloccate':
            return __('SCEGLI IL VERSO');
        case 'Pim Divani':
            if (
                sid_abbonati
                    .filter((a) => a !== undefined)
                    .find((a) => a.label === 'sinistro') !== undefined
            ) {
                return __('SCEGLI LA POSIZIONE DELLA PENISOLA');
            }

            return __('SCEGLI IL MODELLO DEL DIVANO');
        case 'Pim Tavoli':
        case 'Pim Multiuso e Scarpiere':
            return __('SCEGLI LA MISURA DEL TAVOLO CHE PREFERISCI');
        default:
            return '';
        }
    }

    renderLabelAbbinati(label) {
        switch (label) {
        case 'con contenitore':
            return __('LETTO CONTENITORE');
        case 'giroletto':
            return __('LETTO GIROLETTO');
        case 'Letto non presente':
            return __('SENZA LETTO');
        case 'a terra':
            return __('A TERRA');
        case 'sospesi':
            return __('SOSPESO');
        case 'Letto matrimoniale':
            return __('matrimoniale');
        case 'Letto 1 piazza e mezza':
            return __('1 piazza e mezza');
        case 'Letto singolo':
            return __('singolo');
        case '2 POSTI':
            return __('2 POSTI');
        case '3 POSTI':
            return __('3 POSTI');
        case '2 posti':
            return __('2 POSTI');
        case '3 posti':
            return __('3 POSTI');
        case 'sinistro':
            return __('a sinistra');
        case 'destro':
            return __('a destra');
        case 'Comodino':
            return __('Comodino');
        case 'Settimino':
            return __('Settimino');
        case 'Comò':
            return __('Como');
        default:
            return __(label);
        }
    }

    renderPrezzo() {
        const {
            product: {
                price_range: dataProd,
                categories,
                sku,
            },
            offerType,
            offerCount,
            device: { isMobile },
        } = this.props;

        return (
          <div
            block="ProductActions"
            elem="Schema"
            itemType={ offerType }
            itemProp="offers"
            itemScope
          >
              <div
                block="ProductActions"
                elem="PriceWrapper"
              >
                  { /* { this.renderConfigurablePriceBadge() } */ }
                  { this.renderSchema() }
                  <ProductPrice
                    reverse={ isMobile }
                    promoPosition="top-miglior-prezzo"
                    isSchemaRequired
                    variantsCount={ offerCount }
                    price={ dataProd }
                    categories={ categories }
                    id={ sku }
                    mix={ { block: 'ProductActions', elem: 'Price' } }
                  />
                  { window.matchMedia('(max-width: 767px)').matches && this.renderCodice() }
              </div>
          </div>
        );
    }

    renderNameAndBrand() {
        const {
            product: { name, nuovo_template_option, logo_elettrodomestico },
        } = this.props;

        let certificationCondition = true;

        /* old template in mobile do not show in that position */
        if (
            nuovo_template_option.label === 'false'
            && window.matchMedia('(max-width: 810px)').matches
        ) {
            certificationCondition = false;
        }

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'name' } }
            >
                { nuovo_template_option.label
                    === 'template_elettrodomestici' && (
                    <div className="justify-content-between">
                        <h1 block="ProductActions" elem="Title" itemProp="name">
                            <TextPlaceholder content={ name } length="medium" />
                        </h1>
                        <div>
                            <img
                              className="img-elettrodomestico"
                              src={ logo_elettrodomestico }
                              alt="logo_elettrodomestico"
                            />
                        </div>
                    </div>
                ) }
                { nuovo_template_option.label
                    !== 'template_elettrodomestici' && (
                    <div className="d-flex justify-content-between">
                        <h1 block="ProductActions" elem="Title" itemProp="name">
                            <TextPlaceholder content={ name } length="medium" />
                        </h1>
                        <div className="no-flex-basis">
                            { certificationCondition
                                && this.renderCertification() }
                        </div>
                    </div>
                ) }
            </section>
        );
    }

    renderMcFlashSale() {
        const {
            product: { sku, mc_flashsale },
        } = this.props;

        return (
            mc_flashsale
            && mc_flashsale.items_total > 0
            && sku && (
                <div block="ProductActions" elem="FlashSale">
                    <FlashSaleIndicator
                      sku={ sku }
                      flashsale={ mc_flashsale }
                      isInfoIcon={ false }
                    />
                </div>
            )
        );
    }

    renderMcBlackFridayCounter() {
        const {
            product: { sku, mc_black_friday_counter },
        } = this.props;

        return (
            mc_black_friday_counter
            && mc_black_friday_counter.items_total > 0
            && sku && (
                <div block="ProductActions" elem="BlackFridayCounter">
                    <BlackFridayCounter
                      sku={ sku }
                      blackFriday={ mc_black_friday_counter }
                      isInfoIcon={ false }
                    />
                </div>
            )
        );
    }

    renderMetodoDiConsegna() {
        const {
            showPopup,
            product: {
                mc_national_carrier,
                mc_trasporto_montaggio,
                mc_ritiro_cliente,
                mc_venditacc_tablet,
            },
        } = this.props;

        const loaderPlaceholderSize = {
            width: '100%',
            height: '200px',
        };

        return (
            <>
                <div
                  block="ProductActions"
                  elem="InformazioniLink"
                  role="button"
                  tabIndex="0"
                  onClick={ () => {
                      showPopup(METODO_CONSEGNA_CUSTOM_POPUP);
                  } }
                  onKeyDown={ () => {
                      showPopup(METODO_CONSEGNA_CUSTOM_POPUP);
                  } }
                >
                    <dt block="ProductActions" elem="MetodoDiConsegnaIcon" />
                    <dd block="ProductActions" elem="MetodoDiConsegnaText">
                        { __('Delivery method') }
                    </dd>
                </div>

                <Popup isSidePopup id={ METODO_CONSEGNA_CUSTOM_POPUP }>
                    <div className="shipping-list">
                        <h1>{ __('Shipping Methods') }</h1>
                        { mc_trasporto_montaggio === 1 && (
                            <CmsBlock
                              placeHolderSize={ loaderPlaceholderSize }
                              identifier="popup_metodo_di_consegna_tm"
                            />
                        ) }
                        { mc_national_carrier === 1 && (
                            <CmsBlock
                              placeHolderSize={ loaderPlaceholderSize }
                              identifier="popup_metodo_di_consegna_corriere"
                            />
                        ) }
                        { mc_venditacc_tablet === 1 && (
                            <CmsBlock
                              placeHolderSize={ loaderPlaceholderSize }
                              identifier="popup_metodo_di_consegna_sottocasatua"
                            />
                        ) }
                        { mc_ritiro_cliente === 1 && (
                            <CmsBlock
                              placeHolderSize={ loaderPlaceholderSize }
                              identifier="popup_metodo_di_consegna_ritiro"
                            />
                        ) }
                    </div>
                </Popup>
            </>
        );
    }

    renderMetodoDiConsegnaPopup() {
        const {
            product: {
                mc_national_carrier,
                mc_trasporto_montaggio,
                mc_ritiro_cliente,
                mc_venditacc_tablet,
            },
            shippingPrice,
            device,
        } = this.props;
        let trasporto = '';
        let corriere = '';

        let sotto_casa_tua = '';
        let trasporto_img = '';
        let corriere_img = '';

        let sotto_casa_tua_img = '';

        let ritiro = '';

        if (shippingPrice) {
            trasporto = shippingPrice?.trasporto?.prezzo;
            corriere = shippingPrice?.corriere?.prezzo;

            sotto_casa_tua = shippingPrice?.sottocasa?.prezzo;

            trasporto_img = shippingPrice?.trasporto?.promo_img;
            corriere_img = shippingPrice?.corriere?.promo_img;

            sotto_casa_tua_img = shippingPrice?.sottocasa?.promo_img;

            ritiro = 0;
        }

        return (
            <Popup
              isSidePopup={ false }
              id={ METODO_CONSEGNA_CUSTOM_POPUP_NEW }
              customClass="shippingListPopupProduct"
            >
                <div className="shipping-list">
                    <h1>{ __('Modalità di consegna per questo prodotto') }</h1>
                    { mc_ritiro_cliente === 1 && (
                        <div className="singleShipping">
                            <h5>Ritiro a magazzino</h5>
                            <h5 className={ ritiro === 0 ? 'green' : '' }>
                                { ritiro === 0 ? 'Gratis' : `${ritiro} €` }
                            </h5>
                        </div>
                    ) }
                    { mc_national_carrier === 1 && (
                        <div className="singleShipping">
                            <h5>Corriere Nazionale</h5>
                            <div className="singleShippingDeepCont">
                                { corriere_img !== null
                                    && corriere_img !== undefined && (
                                        <img
                                          alt="sconto"
                                          src={ `https://media.mondoconv.it/${corriere_img}` }
                                          onError={ (e) => {
                                              e.target.style.display = 'none'; // Nascondi l'immagine se non si carica
                                          } }
                                        />
                                ) }
                                <h5 className={ corriere === 0 ? 'green' : '' }>
                                    { corriere === 0
                                        ? 'Gratis'
                                        : `${corriere} €` }
                                </h5>
                            </div>
                        </div>
                    ) }
                    { mc_venditacc_tablet === 1 && (
                        <div className="singleShipping">
                            <h5>
                                Sotto casa tua
                                { device.isMobile && <br /> }
                                { !device.isMobile && ' ' }
                                (consegna al piano strada)
                            </h5>
                            <div className="singleShippingDeepCont">
                                { sotto_casa_tua_img !== null
                                    && sotto_casa_tua_img !== undefined && (
                                        <img
                                          alt="sconto"
                                          src={ `https://media.mondoconv.it/${sotto_casa_tua_img}` }
                                          onError={ (e) => {
                                              e.target.style.display = 'none'; // Nascondi l'immagine se non si carica
                                          } }
                                        />
                                ) }
                                <h5
                                  className={ sotto_casa_tua === 0 ? 'green' : '' }
                                >
                                    { sotto_casa_tua === 0
                                        ? 'Gratis'
                                        : `${sotto_casa_tua} €` }
                                </h5>
                            </div>
                        </div>
                    ) }
                    { mc_trasporto_montaggio === 1 && (
                        <div className="singleShipping">
                            <h5>Trasporto e montaggio</h5>
                            <div className="singleShippingDeepCont">
                                { trasporto_img !== null
                                    && trasporto_img !== undefined && (
                                        <img
                                          alt="sconto"
                                          src={ `https://media.mondoconv.it/${trasporto_img}` }
                                          onError={ (e) => {
                                              e.target.style.display = 'none'; // Nascondi l'immagine se non si carica
                                          } }
                                        />
                                ) }
                                <h5 className={ trasporto === 0 ? 'green' : '' }>
                                    { trasporto === 0
                                        ? 'Gratis'
                                        : `${trasporto} €` }
                                </h5>
                            </div>
                        </div>
                    ) }
                </div>
            </Popup>
        );
    }

    renderGaranzia() {
        const { showPopup, getAnniGaranzia } = this.props;

        const anniGaranzia = getAnniGaranzia();

        const cms_block = 'popup_anni_garanzia_';

        return (
            anniGaranzia > 0 && (
                <>
                    <div
                      block="ProductActions"
                      elem="InformazioniLink"
                      role="button"
                      tabIndex="0"
                      onClick={ () => showPopup(GARANZIA_CUSTOM_POPUP) }
                      onKeyDown={ () => showPopup(GARANZIA_CUSTOM_POPUP) }
                    >
                        <dt block="ProductActions" elem="GaranziaIcon" />
                        <dd block="ProductActions" elem="GaranziaText">
                            { anniGaranzia > 0
                                && __('%s year guarantee', anniGaranzia) }
                        </dd>
                    </div>
                    { anniGaranzia > 0 && (
                        <CmsPopup
                          isSidePopup
                          id={ GARANZIA_CUSTOM_POPUP }
                          cms_block={ cms_block + anniGaranzia }
                        />
                    ) }
                </>
            )
        );
    }

    renderFinanziamento() {
        return (
            <div block="ProductActions" elem="InformazioniLink">
                <dt block="ProductActions" elem="FinanziamentoIcon" />
                <dd block="ProductActions" elem="FinanziamentoText">
                    { __('Pay with the Financing solution') }
                    <br />
                    <Link to="/modalita-pagamento/finanziamento">
                        { __('More information') }
                    </Link>
                </dd>
            </div>
        );
    }

    renderServizi() {
        const { showPopup } = this.props;
        const cms_block = 'popup_servizi';

        return (
            <>
                <div
                  block="ProductActions"
                  elem="InformazioniLink"
                  role="button"
                  tabIndex="0"
                  onClick={ () => showPopup(SERVIZI_CUSTOM_POPUP) }
                  onKeyDown={ () => showPopup(SERVIZI_CUSTOM_POPUP) }
                >
                    <dt block="ProductActions" elem="ServiziIcon" />
                    <dd block="ProductActions" elem="ServiziText">
                        { __('Services') }
                    </dd>
                </div>
                <CmsPopup
                  isSidePopup
                  id={ SERVIZI_CUSTOM_POPUP }
                  cms_block={ cms_block }
                />
            </>
        );
    }

    renderInformazioniAggiuntiveProdotto() {
        return (
            <dl block="ProductActions" elem="InformazioniAggiuntiveProdotto">
                { this.renderDisponibilita() }
                { this.renderMetodoDiConsegna() }
                { this.renderGaranzia() }
                { this.renderFinanziamento() }
                { this.renderServizi() }
            </dl>
        );
    }

    renderCertification() {
        const {
            product: { catas, cosmob, nuovo_template_option },
            showPopup,
            device,
        } = this.props;

        /**
         * cosmob derives from product attributes, isCosmob depends on cosmob AND display rules
         */
        const isCatas = Boolean(catas);
        const isCosmob = Boolean(cosmob);

        if (!(isCatas || isCosmob)) {
            return null;
        }

        const block_name = isCatas ? 'catas' : 'cosmob';
        const popup_name = isCatas
            ? nuovo_template_option.label === 'template_materassi'
                ? CATAS_MATERASSI_POPUP
                : CATAS_CUSTOM_POPUP
            : nuovo_template_option.label === 'template_materassi'
                ? COSMOB_MATERASSI_POPUP
                : COSMOB_CUSTOM_POPUP;
        const certification_name = isCatas ? 'Catas' : 'Cosmob';
        const cms_block = popup_name === COSMOB_MATERASSI_POPUP
            ? 'popup_materassi_cosmob'
            : popup_name === CATAS_MATERASSI_POPUP
                ? 'popup_materassi_catas'
                : `popup_${block_name}`;

        return (
            <>
                <section
                  block="ProductActions"
                  elem="Section"
                  mods={ { type: certification_name } }
                >
                    <div
                      block="ProductActions"
                      elem={ certification_name }
                      role="button"
                      tabIndex="0"
                      onClick={ () => showPopup(popup_name) }
                      onKeyDown={ () => showPopup(popup_name) }
                    />
                </section>
                { device.isMobile && (
                    <CmsPopup
                      isSidePopup
                      id={ popup_name }
                      cms_block={ cms_block }
                    />
                ) }
                { !device.isMobile && (
                    <Popup isFullPopup id={ popup_name }>
                        <CmsBlock identifier={ cms_block } />
                    </Popup>
                ) }
            </>
        );
    }

    renderProposteText() {
        const {
            product: { name, mc_available_colors },
        } = this.props;

        return (
            mc_available_colors.length > 1 && (
                <h2 className="proposte_text">
                    { __('Our proposals for ') }
                    { name }
                </h2>
            )
        );
    }

    renderRateFindomestic() {
        const { product, showPopup, configData } = this.props;
        let prf = '';
        let tvei = '';

        if (configData) {
            prf = configData[0];
            tvei = configData[1];
        }

        const { mc_rata_findomestic_information: infoRata, price_range } = product;
        const logo = 'media/logo/logo-fin2.png';
        const valueForMultiplication = 100;
        const {
            maximum_price: {
                final_price: { value: finalPrice },
            },
        } = price_range;
        const minValueFinanziamento = 199;
        let linkToRedirectRata = `https://secure.findomestic.it/clienti/pmcrs/mondoconvenienza/mcommerce/pages/simulatore.html?versione=L&prf=${prf}&tvei=${tvei}`;

        if (finalPrice && finalPrice > minValueFinanziamento) {
            linkToRedirectRata = `${linkToRedirectRata}&Importo=${
                finalPrice * valueForMultiplication
            }`;
        }

        if (!infoRata) {
            return <p className="rate">{ __('Payable in installments') }</p>;
        }

        return (
            infoRata
            && (infoRata.durata > 0 || infoRata.durata != null) && (
                <>
                    <IfStore storeCode="default">
                        <button
                          className="rate_findomestic_container"
                          onClick={ () => {
                              showPopup('FINAZIAMENTO_FINDOMESTIC');
                          } }
                        >
                            <p className="rate_findomestic">
                                { __('Payable in installments') }
                            </p>
                            <img src={ logo } alt="" />
                        </button>
                        <Popup id="FINAZIAMENTO_FINDOMESTIC">
                            <div className="findomestic_popup">
                                <CmsBlock identifier="rate_findomestic_popup" />
                                <a
                                  target="_blank"
                                  className="Button AddToCart ProductActions-AddToCart"
                                  href={ linkToRedirectRata }
                                  rel="noreferrer"
                                >
                                    { __('Calcola la tua rata') }
                                </a>
                            </div>
                        </Popup>
                    </IfStore>
                    <IfStore storeCode="spain">
                        <p className="rate">{ __('Payable in installments') }</p>
                    </IfStore>
                </>
            )
        );
    }

    renderCameraGiroletto() {
        const {
            product: { camere_giroletto_link, name },
        } = this.props;

        return (
            camere_giroletto_link && (
                <div className="propose_giroletto">
                    <span>{ __('Preferisci la camera %s giroletto', name) }</span>
                    &nbsp;
                    <Link to={ camere_giroletto_link } target="_blind">
                        { __('Click') }
                    </Link>
                </div>
            )
        );
    }

    handleClick() {
        this.setState({ isDropdownShown: !this.state.isDropdownShown });
    }

    renderChooseVariant() {
        const {
            product: {
                mc_product_variants,
                mc_dimension_height,
                mc_dimension_width,
                sku,
                mc_dimension_depth,
                mc_inline_description,
                nuovo_template_option,
                name,
                mc_techspecs_template,
                flusso_varianti,
                mc_versioni_prodotto_information_two = [],
                price_range: {
                    maximum_price: { final_price },
                },
            },
            showPopup,
        } = this.props;

        const { isDropdownShown } = this.state;
        const optionList = [];
        Object.values(mc_product_variants)
            .sort((a, b) =>
                (a.mc_dimension_width > b.mc_dimension_width ? 1 : -1))
            .map((variant) =>
                // inserisco tra le opzioni solo gli armadi differenti dal presente
                (sku !== variant.sku
                    ? optionList.push(
                          <li>
                              <a href={ variant.url } className="closet-anchor">
                                  <span className="closet_description">
                                      { variant.mc_inline_description }
                                  </span>
                                  <span className="closet_dimension">
                                      { variant.mc_dimension_width }
                                      x
                                      { variant.mc_dimension_depth }
                                      x
                                      { variant.mc_dimension_height }
                                      &nbsp;cm
                                  </span>
                                  <span className="closet_price">
                                      { variant.price_range.maximum_price
                                          .final_price.value }
                                      €
                                  </span>
                                  <span className="dropdown-icon-hidden">
                                      &nbsp;
                                  </span>
                              </a>
                          </li>
                    )
                    : null));

        if (
            nuovo_template_option.label !== 'template_armadi2'
            && nuovo_template_option.label !== 'template_bagni'
            && nuovo_template_option.label !== 'template_soggiorni_madie'
            && nuovo_template_option.label !== 'template_sedie_altro'
        ) {
            return (
                mc_product_variants && (
                    <div className="propose_product">
                        <div className="closet_title">
                            { nuovo_template_option.label === 'template_armadi'
                                ? __('Scegli il tuo accessorio armadio')
                                : __('Choose your closet') }
                        </div>
                        <button
                          type="button"
                          className={ isDropdownShown
                              ? 'dropdown-button-clicked'
                              : 'dropdown-button' }
                          onClick={ () => {
                              if (
                                  nuovo_template_option.label
                                        === 'template_armadi'
                                    && Boolean(flusso_varianti)
                              ) {
                                  showPopup(`${VARIANT_POPUP}_${sku}`);
                                  // EVENTO GA4
                                  window.dataLayer.push({
                                      event: 'click_choose',
                                      category: 'click',
                                      action: `choose_${mc_techspecs_template}`,
                                      label: name,
                                  });
                              } else {
                                  this.handleClick();
                              }
                          } }
                        >
                            <div className="closet-anchor">
                                <span className="closet_description">
                                    { nuovo_template_option.label
                                    === 'template_armadi'
                                        ? name
                                        : mc_inline_description }
                                </span>
                                <span className="closet_dimension">
                                    { mc_dimension_width }
                                    x
                                    { mc_dimension_depth }
                                    x
                                    { mc_dimension_height }
                                    &nbsp;cm
                                </span>
                                <span className="closet_price">
                                    { final_price.value }
                                    €
                                </span>
                                <span
                                  className={ isDropdownShown
                                      ? 'dropdown-icon-reversed'
                                      : 'dropdown-icon' }
                                >
                                    &nbsp;
                                </span>
                            </div>
                        </button>
                        { nuovo_template_option.label === 'template_armadi'
                            && this.renderPopupVariant() }
                        { isDropdownShown && (
                            <div className="ul-closet-container">
                                <ul className="closet_variants_dropdown">
                                    <li
                                      id="selectedCloset"
                                      className="selectedItem"
                                    >
                                        <button
                                          className="closet-anchor"
                                          onClick={ this.handleClick }
                                        >
                                            <span className="closet_description">
                                                { mc_inline_description }
                                            </span>
                                            <span className="closet_dimension">
                                                { mc_dimension_width }
                                                x
                                                { mc_dimension_depth }
                                                x
                                                { mc_dimension_height }
                                                &nbsp;cm
                                            </span>
                                            <span className="closet_price">
                                                { final_price.value }
                                                €
                                            </span>
                                            <span className="dropdown-icon-hidden">
                                                &nbsp;
                                            </span>
                                        </button>
                                    </li>
                                    { optionList }
                                </ul>
                            </div>
                        ) }
                        <div className="closet-custom-hr">&nbsp;</div>
                    </div>
                )
            );
        }

        const array = nuovo_template_option.label !== 'template_bagni'
            ? mc_product_variants
            : mc_versioni_prodotto_information_two.filter(
                (a) => a.link != ''
            );

        return (
            Boolean(flusso_varianti)
            && array.length > 0 && (
                <div className="propose_product">
                    <button
                      type="button"
                      className="proposte-button"
                      onClick={ () => {
                          showPopup(`${VARIANT_POPUP}_${sku}`);
                          // EVENTO GA4
                          window.dataLayer.push({
                              event: 'click_choose',
                              category: 'click',
                              action: `choose_${mc_techspecs_template}`,
                              label: name,
                          });
                      } }
                    >
                        { this.renderChooseVariantTitle() }
                    </button>
                    <div className="closet-custom-hr">&nbsp;</div>
                    { this.renderPopupVariant() }
                </div>
            )
        );
    }

    renderPopupVariant() {
        const {
            product: {
                sku,
                placeholder_text,
                flusso_varianti,
                nuovo_template_option,
            },
            linkedProducts: { related: { items = [] } = {} },
        } = this.props;

        return (
            Boolean(flusso_varianti) && (
                <Popup
                  isFullPopup
                  id={ `${VARIANT_POPUP}_${sku}` }
                  clickOutside
                  customClass="Closet"
                >
                    <div block="PopupClosetCard">
                        <h2 block="ColorSelect" elem="Title">
                            { this.renderChooseVariantTitle() }
                        </h2>
                        { nuovo_template_option.label !== 'template_armadi' && (
                            <p>{ placeholder_text }</p>
                        ) }
                        <div block="ColorSelect-Container Closet">
                            { this.renderCard() }
                        </div>
                        { items.length > 0
                            && nuovo_template_option.label !== 'template_armadi'
                            && nuovo_template_option.label
                                !== 'template_bagni' && (
                                <div className="Linked-Products">
                                    <h2 block="ColorSelect" elem="Title">
                                        { this.renderChooseVariantsubTitle() }
                                    </h2>
                                    <div block="ColorSelect-Container Closet">
                                        { this.renderRelatedCard() }
                                    </div>
                                </div>
                        ) }
                    </div>
                </Popup>
            )
        );
    }

    renderChooseVariantsubTitle() {
        const {
            product: { nuovo_template_option, name },
        } = this.props;

        switch (nuovo_template_option.label) {
        case 'template_armadi':
        case 'template_armadi2':
            return __('Scegli gli accessori per la collezione %s', name);
        case 'template_bagni':
            return __('Completa il tuo bagno');
        case 'template_soggiorni_madie':
            return __('Completa il tuo soggiorno');
        default:
            return '';
        }
    }

    renderChooseVariantTitle() {
        const {
            product: { nuovo_template_option },
        } = this.props;

        switch (nuovo_template_option.label) {
        case 'template_armadi':
            return 'Scegli il tuo accessorio armadio';
        case 'template_armadi2':
            return __('Choose your closet');
        case 'template_bagni':
            return __('Scegli il tuo bagno');
        case 'template_soggiorni_madie':
            return __('Scegli il tuo soggiorno');
        case 'template_sedie_altro':
            return __('Vedi tutti i modelli');
        default:
            return '';
        }
    }

    renderRelatedEl() {
        const {
            product: {
                mc_configurations,
                mc_config,
                flusso_varianti,
                sku,
                has_dishwasher,
            },
            linkedProducts: { related: { items = [] } = {} },
            showPopup,
        } = this.props;

        const filteredConfigurations = mc_configurations.filter(
            (a) =>
                a.elImage !== ''
                && String(has_dishwasher) === String(a.has_dishwasher)
        );

        return (
            filteredConfigurations.length > 0
            && Boolean(flusso_varianti) && (
                <div className="propose_product Cucine">
                    <h3>{ __('Scegli gli elettrodomestici da abbinare') }</h3>
                    <button
                      type="button"
                      className="proposte-button"
                      onClick={ () => {
                          showPopup(`${VARIANT_POPUP}_${sku}`);
                      } }
                    >
                        { mc_config }
                    </button>
                    <span>
                        { __(
                            'La cucina rappresentata in foto si riferisce alla cucina base con elettrodomestici a marchio Perla'
                        ) }
                    </span>
                    <Popup
                      isFullPopup
                      id={ `${VARIANT_POPUP}_${sku}` }
                      clickOutside
                      customClass="Closet"
                    >
                        <div block="PopupClosetCard">
                            <h2 block="ColorSelect" elem="Title">
                                { __('Scegli gli elettrodomestici da abbinare') }
                            </h2>
                            <div block="ColorSelect-Container Closet">
                                { this.renderElCard() }
                            </div>
                            { items !== undefined
                                && items !== null
                                && items.length > 0 && (
                                    <div className="Linked-Products">
                                        <h2 block="ColorSelect" elem="Title">
                                            { __(
                                                'Ecco la nostra selezione di lavastoviglie'
                                            ) }
                                        </h2>
                                        <div block="ColorSelect-Container Closet">
                                            { this.renderRelatedCard() }
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </Popup>
                </div>
            )
        );
    }

    renderRelatedCard() {
        const {
            linkedProducts: { related: { items = [] } = {} },
            productPrice,
        } = this.props;

        return (
            <>
                { Object.values(items)
                    .sort(
                        (a, b) =>
                            parseInt(
                                a.attributes?.varianttag_sorting
                                    ?.attribute_value === null
                                    ? 0
                                    : a.attributes?.varianttag_sorting
                                        ?.attribute_value
                            )
                            - parseInt(
                                b.attributes?.varianttag_sorting
                                    ?.attribute_value === null
                                    ? 0
                                    : b.attributes?.varianttag_sorting
                                        ?.attribute_value
                            )
                    )
                    .map((item) => {
                        const selected = window.location.pathname === item.url;
                        const {
                            mc_dimension_width,
                            mc_dimension_depth,
                            mc_dimension_height,
                            mc_product_listing_images: { variant_image },
                        } = item;

                        return (
                            <div
                              block="ColorSelect"
                              elem="Option"
                              mods={ { selected } }
                              className="Closet"
                            >
                                <a href={ item.url }>
                                    <img
                                      src={ variant_image === ''
                                          ? item.small_image.url
                                          : variant_image }
                                      alt={ item.name }
                                    />
                                </a>
                                <a
                                  href={ item.url }
                                  className="DescriptionClosetContainer"
                                >
                                    <div className="DescriptionCloset">
                                        <div>
                                            <h3>
                                                { item.mc_inline_description }
                                            </h3>
                                            <h2>
                                                cm.&nbsp;
                                                { mc_dimension_width }
                                                &nbsp;x&nbsp;
                                                { mc_dimension_depth }
                                                &nbsp;x&nbsp;
                                                { mc_dimension_height }
                                            </h2>
                                        </div>
                                        <div block="PriceCloset">
                                            <ProductPrice
                                              promoPosition="stickerPopup"
                                              price={ productPrice }
                                            />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        );
                    }) }
            </>
        );
    }

    renderElCard() {
        const {
            product: { mc_configurations, has_dishwasher },
        } = this.props;

        const filteredConfigurations = mc_configurations
            .filter(
                (a) =>
                    a.elImage !== ''
                    && String(has_dishwasher) === String(a.has_dishwasher)
            )
            .sort((a, b) => a.price_delta - b.price_delta);

        return (
            <>
                { Object.values(filteredConfigurations).map((item) => {
                    const {
                        ElDesc,
                        elName,
                        price_delta,
                        url,
                        elImage,
                    } = item;

                    const price = price_delta >= 0
                        ? `+${String(price_delta)}`
                        : String(price_delta);

                    return (
                        <div
                          block="ColorSelect"
                          elem="Option"
                          className={ `Closet ${
                              url === window.location.pathname
                                  ? 'selected'
                                  : ''
                          }` }
                        >
                            <a href={ url }>
                                <img src={ elImage } alt={ name } />
                            </a>
                            <a
                              href={ url }
                              className="DescriptionClosetContainer"
                            >
                                <div className="DescriptionCloset">
                                    <div>
                                        <h3>{ elName }</h3>
                                        <h2 className="cucine">{ ElDesc }</h2>
                                    </div>
                                    <div block="PriceCloset">
                                        <p className="ProductPrice ProductActions-Price">
                                            <ins className="ProductPrice-CurrentPrice">
                                                <span
                                                  itemProp="price"
                                                  content="825"
                                                >
                                                    { price }
                                                    <span className="currency">
                                                        €
                                                    </span>
                                                </span>
                                            </ins>
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    );
                }) }
            </>
        );
    }

    renderCard() {
        const {
            productPrice,
            product,
            product: {
                mc_product_variants,
                mc_dimension_depth,
                mc_dimension_height,
                mc_dimension_width,
                url,
                mc_inline_description,
                small_image,
                name,
                nuovo_template_option,
                mc_versioni_prodotto_information_two = [],
                mc_product_listing_images: { variant_image },
            },
        } = this.props;

        const elem = mc_versioni_prodotto_information_two
            .filter((a) => a.link != '')
            .find((a) => a.url === window.location.pathname);

        const version = [elem]
            .concat(
                mc_versioni_prodotto_information_two
                    .filter((a) => a.link != '')
                    .filter((a) => a.url !== window.location.pathname)
            )
            .filter((a) => a !== undefined);

        const array = nuovo_template_option.label !== 'template_bagni'
            ? nuovo_template_option.label !== 'template_soggiorni_madie'
                ? mc_product_variants
                : mc_product_variants.filter((a) => Boolean(a.is_soggiorno))
            : version;

        return (
            <>
                { nuovo_template_option.label !== 'template_bagni' && (
                    <div
                      block="ColorSelect"
                      elem="Option"
                      className="Closet selected"
                    >
                        <Link to={ url }>
                            <img
                              src={ variant_image === ''
                                  ? small_image.url
                                  : variant_image }
                              alt={ name }
                            />
                        </Link>
                        <Link to={ url } className="DescriptionClosetContainer">
                            <div className="DescriptionCloset">
                                <div>
                                    <h3>
                                        { nuovo_template_option.label
                                        === 'template_armadi'
                                            ? name
                                            : mc_inline_description }
                                    </h3>
                                    <h2>
                                        cm.
                                        { ' ' }
                                        { mc_dimension_width }
                                        { ' ' }
                                        x
                                        { ' ' }
                                        { mc_dimension_depth }
                                        { ' ' }
                                        x
                                        { ' ' }
                                        { mc_dimension_height }
                                    </h2>
                                </div>
                                <div block="PriceCloset">
                                    <ProductPrice
                                      promoPosition="stickerPopup"
                                      price={ productPrice }
                                    />
                                </div>
                            </div>
                        </Link>
                        { this.renderAddToCartPopup(product) }
                    </div>
                ) }

                { Object.values(array)
                    .sort((a, b) =>
                        (nuovo_template_option.label !== 'template_bagni'
                            ? parseInt(
                                a.varianttag_sorting === null
                                    ? 0
                                    : a.varianttag_sorting
                            )
                              - parseInt(
                                  b.varianttag_sorting === null
                                      ? 0
                                      : b.varianttag_sorting
                              )
                            : null))
                    .map((item) => {
                        const selected = window.location.pathname === item.url;
                        const {
                            mc_dimension_width,
                            mc_dimension_depth,
                            mc_dimension_height,
                            mc_product_listing_images: { variant_image },
                        } = item;

                        return (
                            <div
                              block="ColorSelect"
                              elem="Option"
                              mods={ { selected } }
                              className={ `Closet ${
                                  selected ? 'selected' : ''
                              }` }
                            >
                                <a href={ item.url }>
                                    <img
                                      src={ variant_image === ''
                                          ? item.small_image.url
                                          : variant_image }
                                      alt={ item.name }
                                    />
                                </a>
                                <a
                                  href={ item.url }
                                  className="DescriptionClosetContainer"
                                >
                                    <div className="DescriptionCloset">
                                        <div>
                                            <h3>
                                                { nuovo_template_option.label
                                                === 'template_armadi'
                                                    ? item.name
                                                    : item.mc_inline_description }
                                            </h3>
                                            <h2>
                                                cm.&nbsp;
                                                { mc_dimension_width }
                                                &nbsp;x&nbsp;
                                                { mc_dimension_depth }
                                                &nbsp;x&nbsp;
                                                { mc_dimension_height }
                                            </h2>
                                        </div>
                                        <div block="PriceCloset">
                                            <ProductPrice
                                              promoPosition="imageStickerToSelect"
                                              price={ item.price_range }
                                            />
                                        </div>
                                    </div>
                                </a>
                                { this.renderAddToCartPopup(item) }
                            </div>
                        );
                    }) }
            </>
        );
    }

    renderRelatedCloset() {
        const {
            product: { closet_related = [] },
        } = this.props;

        return (
            closet_related.filter((elem) => elem.label).length > 0 && (
                <div block="ProductActions" elem="RelatedCloset">
                    <p className="title">
                        { __('Questo prodotto può essere abbinato solo con:') }
                    </p>
                    { closet_related
                        .filter((elem) => elem.label)
                        .map((elem) => (
                            <a href={ elem.url }>{ elem.label }</a>
                        )) }
                </div>
            )
        );
    }

    renderTemplateCucine() {
        return (
            <>
                <div
                  block="ProductActions"
                  elem="AddToCartWrapper"
                  mix={ { block: 'FixedElement', elem: 'Bottom' } }
                >
                    { this.renderFieldHidden() }
                </div>
                { this.renderADOQty() }
                <div className="clearfix">
                    { this.renderProposteText() }
                    { window.matchMedia('(max-width: 767px)').matches
                        && this.renderCertification() }
                </div>
                { this.renderColorSelect() }
                { window.matchMedia('(min-width: 768px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateCucineBloccate() {
        const {
            showPopup,
            product,
            product: { flusso_varianti },
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 768px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                    <button
                                      className="consegna"
                                      onClick={ () =>
                                          showPopup(
                                              METODO_CONSEGNA_CUSTOM_POPUP_NEW
                                          ) }
                                    >
                                        Modalità di consegna
                                    </button>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                        <button
                                          className="consegna"
                                          onClick={ () =>
                                              showPopup(
                                                  METODO_CONSEGNA_CUSTOM_POPUP_NEW
                                              ) }
                                        >
                                            Modalità di consegna
                                        </button>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                <div className="clearfix d-flex">
                    { this.renderProposteText() }
                    { window.matchMedia('(max-width: 767px)').matches
                        && this.renderCertification() }
                </div>
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
                { !flusso_varianti && this.renderSelectConfiguration() }
                { this.renderConfiguration() }
                { this.renderRelatedEl() }
                { this.renderMetodoDiConsegnaPopup() }
            </>
        );
    }

    renderTemplateDivaniOrCucineV2() {
        const {
            product,
            product: { nuovo_template_option },
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 768px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { /* da 768 a 810 px */ }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                { nuovo_template_option.label === 'template_divani' || nuovo_template_option.label === 'template_divani_2'
                    ? this.renderButtonAbbinatiDivani()
                    : this.renderButtonAbbinati() }

                { nuovo_template_option.label === 'template_divani'
                    && window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcFlashSale() }
                { nuovo_template_option.label === 'template_divani_2'
                    && window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcFlashSale() }
                { nuovo_template_option.label
                    === 'template_cucine_componibiliV2' && (
                    <div className="clearfix d-flex">
                        { this.renderProposteText() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderCertification() }
                    </div>
                ) }
                { nuovo_template_option.label === 'template_divani'
                    && this.renderProposteText() }
                { nuovo_template_option.label === 'template_divani_2'
                    && this.renderProposteText() }
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateCamere() {
        const {
            product,
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 768px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { product.camere_giroletto !== 0 && this.renderCameraGiroletto() }
                { this.renderADOQty() }
                { this.renderButtonAbbinati() }
                <div className="clearfix d-flex">
                    { this.renderProposteText() }
                    { window.matchMedia('(max-width: 810px)').matches
                        && this.renderCertification() }
                </div>
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderADOQty() {
        const { isADO } = this.state;
        const { thirdLevelQTY } = this.props;

        return (
            isADO && (
                <div className="qtyADO">
                    <span>{ this.renderQtyMsg(thirdLevelQTY) }</span>
                </div>
            )
        );
    }

    renderQtyMsg(value) {
        const { isADOLoaded } = this.props;

        if (!isADOLoaded) {
            switch (true) {
            case value === 0:
                return (
                        <div
                          block="ProductActions"
                          elem="ADOQuantity"
                          className="outOfStock"
                        >
                            { __('PRODOTTO TERMINATO') }
                        </div>
                );
            case value === 1:
                return (
                        <div
                          block="ProductActions"
                          elem="ADOQuantity"
                          className="onlyOne"
                        >
                            { __('Ne resta solo 1 nella data indicata.') }
                            <div />
                            { __(
                                'La data disponibile potrebbe variare per ordini multipli.'
                            ) }
                        </div>
                );
            case value > 1 && value < 11:
                return (
                        <div
                          block="ProductActions"
                          elem="ADOQuantity"
                          className="onlyFew"
                        >
                            { __(
                                'Ne restano solo %s nella data indicata',
                                value
                            ) }
                            <div />
                            { __(
                                'La data disponibile potrebbe variare per ordini multipli.'
                            ) }
                        </div>
                );
            default:
                break;
            }
        } else if (!BrowserDatabase.getItem('mc_warehouse')) {
            return <TextPlaceholder content={ null } />;
        }

        return null;
    }

    renderTemplateLetti() {
        const {
            product,
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 811px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                { this.renderButtonAbbinati() }
                { this.renderDescrizioniAbbinati() }
                <div className="clearfix d-flex">
                    { this.renderProposteText() }
                </div>
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateCamerette() {
        const {
            product,
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 811px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                <div className="clearfix d-flex">
                    { this.renderProposteText() }
                </div>
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateBagni() {
        const {
            product,
            product: { nuovo_template_option },
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 811px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                { this.renderButtonAbbinati() }
                { this.renderMisureAbbinati() }
                { nuovo_template_option.label === 'template_divani'
                    && window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcFlashSale() }
                { nuovo_template_option.label === 'template_divani_2'
                    && window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcFlashSale() }
                { nuovo_template_option.label
                    === 'template_cucine_componibiliV2' && (
                    <div className="clearfix d-flex">
                        { this.renderProposteText() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderCertification() }
                    </div>
                ) }
                { (nuovo_template_option.label === 'template_divani'
                    || nuovo_template_option.label === 'template_divani_2'
                    || nuovo_template_option.label === 'template_bagni')
                    && this.renderProposteText() }
                { this.renderChooseVariant() }
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateSoggiorniMadie() {
        const {
            product,
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 811px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                { this.renderChooseVariant() }
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateSedieAltro() {
        const {
            product,
            product: { nuovo_template_option },
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 811px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                { this.renderButtonAbbinati() }
                { nuovo_template_option.label === 'template_divani'
                    && window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcFlashSale() }
                { nuovo_template_option.label === 'template_divani_2'
                    && window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcFlashSale() }
                { nuovo_template_option.label
                    === 'template_cucine_componibiliV2' && (
                    <div className="clearfix d-flex">
                        { this.renderProposteText() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderCertification() }
                    </div>
                ) }
                { nuovo_template_option.label === 'template_divani'
                    && this.renderProposteText() }
                { nuovo_template_option.label === 'template_divani_2'
                    && this.renderProposteText() }
                { this.renderChooseVariant() }
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateCameretteBloccate() {
        const {
            product,
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 811px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                { this.renderButtonAbbinati() }
                <div className="clearfix d-flex">
                    { this.renderProposteText() }
                </div>
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateTavoli() {
        const {
            product,
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 811px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                { this.renderButtonAbbinati() }
                <div className="clearfix d-flex">
                    { this.renderProposteText() }
                </div>
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateMaterassi() {
        const {
            product,
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 811px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                <div className="clearfix d-flex">
                    { this.renderProposteText() }
                </div>
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateElettrodomestici() {
        const {
            product,
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 811px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                <div className="clearfix d-flex">
                    { this.renderProposteText() }
                </div>
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateReti() {
        const {
            product,
            product: { nuovo_template_option },
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 811px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                { nuovo_template_option.label === 'template_divani'
                    && window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcFlashSale() }
                { nuovo_template_option.label === 'template_divani_2'
                    && window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcFlashSale() }
                { nuovo_template_option.label
                    === 'template_cucine_componibiliV2' && (
                    <div className="clearfix d-flex">
                        { this.renderProposteText() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderCertification() }
                    </div>
                ) }
                { (nuovo_template_option.label === 'template_divani'
                    || nuovo_template_option.label === 'template_divani_2'
                    || nuovo_template_option.label === 'template_reti')
                    && this.renderProposteText() }
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderTemplateArmadi() {
        const {
            product,
            product: {
                price_range: { miglior_prezzo },
            },
        } = this.props;

        return (
            <>
                { window.matchMedia('(min-width: 811px)').matches && (
                    <>
                        <div block="ProductActions" elem="NameAndPriceWrapper">
                            <div block="ProductActions" elem="NameWrapper">
                                { this.renderNameAndBrand() }
                            </div>
                        </div>

                        { this.renderMcDescrizione() }
                        { this.renderMcColorInfo() }
                        { this.renderMcDimensioni() }
                        { this.renderSchedaTecnicaLink() }
                        { window.matchMedia('(max-width: 810px)').matches
                            && this.renderMcBlackFridayCounter() }
                        <div block="ProductActions" elem="PriceAndButton">
                            { this.renderPrezzo() }
                            { miglior_prezzo === '1' && (
                                <div
                                  className="custom_cont"
                                  style={ {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: '10px',
                                      alignItems: 'end',
                                  } }
                                >
                                    <div className="cstm_div">
                                        { this.renderCodice() }
                                        { Object.keys(product).length > 0
                                            && this.renderAddToCart() }

                                        <div
                                          className="cucine_rate_cont"
                                          style={ { maxWidth: '200px' } }
                                        >
                                            { this.renderRateFindomestic() }
                                        </div>
                                    </div>
                                </div>
                            ) }

                            { miglior_prezzo !== '1'
                                && Object.keys(product).length > 0 && (
                                    <div
                                      className="custom_cont"
                                      style={ {
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: '10px',
                                          alignItems: 'end',
                                      } }
                                    >
                                        <div className="cstm_div">
                                            { this.renderCodice() }
                                            { Object.keys(product).length > 0
                                                && this.renderAddToCart() }

                                            <div
                                              className="cucine_rate_cont"
                                              style={ { maxWidth: '200px' } }
                                            >
                                                { this.renderRateFindomestic() }
                                            </div>
                                        </div>
                                    </div>
                            ) }
                        </div>
                    </>
                ) }
                { this.renderADOQty() }
                { this.renderRelatedCloset() }
                { this.renderChooseVariant() }
                { this.renderProposteText() }
                { this.renderColorSelect() }
                { this.renderDisponibilita() }
                { window.matchMedia('(min-width: 811px)').matches
                    && this.renderMcBlackFridayCounter() }
            </>
        );
    }

    renderOldTemplate() {
        const {
            product,
            product: { mc_flashsale },
            product: { mc_black_friday_counter },
        } = this.props;
        const { discount_image } = mc_black_friday_counter || mc_flashsale || {};
        const { sm: image } = discount_image || {};

        return (
            <div block="old-template">
                { this.renderPrezzo() }

                <div
                  block="ProductActions"
                  elem="AddToCartWrapper"
                  mix={ { block: 'FixedElement', elem: 'Bottom' } }
                >
                    { /* this.renderQuantityInput() */ }
                    { this.renderFieldHidden() }
                </div>
                { this.renderReviewSection() }

                <div block="ProductActions" elem="NameAndPriceWrapper">
                    <div block="ProductActions" elem="NameWrapper">
                        { this.renderNameAndBrand() }
                    </div>
                    <div block="ProductActions" elem="PrezzoMobile">
                        { this.renderPrezzo() }
                    </div>
                </div>
                { /* { window.matchMedia('(max-width: 810px)').matches && this.renderCertification() } */ }
                { this.renderMcDescrizione() }
                { this.renderMcColorInfo() }

                { this.renderMcDimensioni() }
                { this.renderSchedaTecnicaLink() }
                { this.renderMcComposizioneTipo() }
                { this.renderMcDescrizione2() }
                { this.renderMcFlashSale() }
                { this.renderMcBlackFridayCounter() }
                { /* this.renderSkuAndStock() */ }
                { this.renderConfigurableOptions() }
                { this.renderCustomizableOptions() }
                { this.renderBundleOptions() }
                { this.renderGroupedOptions() }
                { this.renderTierPrices() }
                { this.renderSconto(image) }
                { this.renderCodice() }
                { this.renderADOQty() }
                { this.renderFlexMediaButton() }
                { this.renderSelectConfiguration() }
                { this.renderColoriDisponibili() }
                { /* this.renderDisponibilita() */ }
                { this.renderColorSelect() }
                { Object.keys(product).length > 0 && this.renderAddToCart() }
                { this.renderInformazioniAggiuntiveProdotto() }
            </div>
        );
    }

    render() {
        return <article block="ProductActions">{ this.templatePage() }</article>;
    }
}

export default ProductActionsComponent;
